body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

img {
  max-width: 100%;
}


.container {
  max-width: 1440px;
  padding: 0 16px;
  margin: auto;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: ''
}

.slick-track:after {
  clear: both
}

.slick-loading .slick-track {
  visibility: hidden
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-loading .slick-slide {
  visibility: hidden
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0
}

.slick-prev {
  left: -50px
}

[dir=rtl] .slick-prev {
  right: -25px;
  left: auto
}

.slick-prev:before {
  font-family: "Material Symbols Outlined";
  content: "\e5c4";
}

[dir=rtl] .slick-prev:before {
  content: '→'
}

.slick-next {
  right: -35px
}

[dir=rtl] .slick-next {
  right: auto;
  left: -25px
}

.slick-next:before {
  font-family: "Material Symbols Outlined";
  content: "\e5c8";
}

[dir=rtl] .slick-next:before {
  content: '←'
}

.slick-dotted.slick-slider {
  margin-bottom: 30px
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  content: '';
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #000;
  background-color: #fff;
}


.trade-slider {
  .slick-prev:before {
      font-family: "Material Symbols Outlined";
      content: "\e5e0";
      font-size: 36px !important;
  }
  .slick-next:before {
      font-family: "Material Symbols Outlined";
      content: "\e5e1";
      font-size: 36px !important;
  }

}


.form-group{
  padding: 10px 0;
  position: relative;
}

.form-group label{
  font-size: 14px;
  padding: 0 0 4px;
  display: block;
  color: #FFF;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-group input, .form-group textarea {
  width: 100%;
  border: 1.6px solid #4a4c4e;
  background-color: transparent;
  font-size: 14px;
  color: #FFF;
  padding: 12px 14px;
  border-radius: 4px;
  outline: none;
  letter-spacing: 0.5px;
  letter-spacing: 1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 2px;
  box-sizing: border-box;
  &:focus{
    border: 1.6px solid #129cd9;
  }
  &.error {
      border: 1.6px solid #FF5349;
  }
}

.error-text{
  color: #FF5349;
  font-size: 13px;
  letter-spacing: 1px;
}

.form-group textarea{
  min-height: 80px;
  resize: none;
}